var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-0 mt-2" },
    [
      _c(
        "v-flex",
        [
          _c(_vm.componentName, {
            tag: "component",
            attrs: { "timeline-item": _vm.timelineItem }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }