














































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { MyStarlingState, Timelines } from '@/scripts/store/modules/my_starling/types';
import ProfileTimeline from '@/views/profile/partials/timeline/profile-timeline.vue';
import { gatherAvailableFilters, timelinesFilter } from '@/scripts/store/modules/my_starling/utils';

export default Vue.extend({
  name: 'profile-timelines',
  components: { ProfileTimeline },
  data() {
    return {
      loading: true,
      selectedFilters: [] as string[],
    };
  },
  computed: {
    ...mapState({
      myStarling: (state: any): MyStarlingState => {
        return state.myStarling;
      },
    }),
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
    availableFilters(): Array<string> {
      if (!this.myStarling.timelines || !this.myStarling.timelines.timelines || !this.myStarling.timelines.timelines.length) return [];
      const filters = gatherAvailableFilters(this.myStarling.timelines);

      return filters
        .map((filter) => ({
          filter,
          weight: (this.$te(`app.profile.my_starling.timeline.filters.${filter}.weight`) ? this.$t(`app.profile.my_starling.timeline.filters.${filter}.weight`) : 0) as number,
        }))
        .sort((a, b) => b.weight - a.weight)
        .map(({ filter }) => filter);
    },
    filteredTimelines(): undefined | Timelines {
      if (!this.myStarling.timelines || !this.myStarling.timelines.timelines || !this.myStarling.timelines.timelines.length) return undefined;
      return timelinesFilter(this.myStarling.timelines, this.selectedFilters);
    },
  },
  mounted() {
    this.$store.dispatch('myStarling/getTimelines').finally(() => {
      this.loading = false;
    });
  },
  methods: {
    findFilterIndex(filterValue: string) {
      return this.selectedFilters.findIndex(e => e === filterValue);
    },
    isSelected(filterValue: string) {
      return this.findFilterIndex(filterValue) >= 0;
    },
    selectAll() {
      this.selectedFilters.splice(0, this.selectedFilters.length);
    },
    toggleFilter(filterValue: string) {
      const index = this.findFilterIndex(filterValue);
      if (index >= 0) {
        this.selectedFilters.splice(index, 1);
      } else {
        this.selectedFilters.push(filterValue);
      }
    },
  },
});
