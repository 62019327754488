












import Vue, { PropType } from 'vue';
import { Milestone, TimelineItem } from '@/scripts/store/modules/my_starling/types';
import ProfileMilestone from '@/views/profile/partials/milestones/profile-milestone.vue';
import milestoneBus from '@/scripts/eventbus/milestoneEventBus';

export default Vue.extend({
  name: 'profile-timeline-item-milestone',
  components: { ProfileMilestone },
  props: {
    timelineItem: Object as PropType<TimelineItem>,
  },
  data() {
    return {
      loading: false,
      milestone: undefined as undefined | Milestone,
    };
  },
  methods: {
    visibilityChanged(visible: boolean) {
      if (visible && !this.loading && !this.milestone) {
        this.loading = true;
        this.$store.dispatch('myStarling/getMilestone', this.timelineItem?.content?.id as string).then((res: Milestone) => {
          this.milestone = res;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    onSelected(milestone: Milestone) {
      milestoneBus.$emit('open', milestone);
    },
  },
});
