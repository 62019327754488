








import Vue, { PropType } from 'vue';
import { TimelineItem } from '@/scripts/store/modules/my_starling/types';
import ProfileTimelineItemTherapy from '@/views/profile/partials/timeline/profile-timeline-item-therapy.vue';
import ProfileTimelineItemCertificate from '@/views/profile/partials/timeline/profile-timeline-item-certificate.vue';
import ProfileTimelineItemCheckup from '@/views/profile/partials/timeline/profile-timeline-item-checkup.vue';
import ProfileTimelineItemMilestone from '@/views/profile/partials/timeline/profile-timeline-item-milestone.vue';

export default Vue.extend({
  name: 'profile-timeline-item',
  components: { ProfileTimelineItemMilestone, ProfileTimelineItemCheckup, ProfileTimelineItemCertificate, ProfileTimelineItemTherapy },
  props: {
    timelineItem: Object as PropType<TimelineItem>,
  },
  computed: {
    componentName() {
      return 'profile-timeline-item-' + this.timelineItem?.content?.type.toLowerCase();
    },
  },
});
