











































import Vue from 'vue';
import { mapState } from 'vuex';
import ProfileMilestone from '@/views/profile/partials/milestones/profile-milestone.vue';
import { Milestone } from '@/scripts/store/modules/my_starling/types';
import milestoneBus from '@/scripts/eventbus/milestoneEventBus';

export default Vue.extend({
  name: 'profile-milestones',
  components: { ProfileMilestone },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      milestones: (state: any): Milestone[] => {
        return state.myStarling.milestones && state.myStarling.milestones.milestones.length ? state.myStarling.milestones.milestones : [];
      },
      mostRecentMilestones(): Milestone[] {
        return this.milestones.slice(0, 3);
      },
    }),
  },
  mounted() {
    this.$store.dispatch('myStarling/getMilestones').finally(() => {
      this.loading = false;
    });
  },
  methods: {
    onSelect(milestone: Milestone) {
      milestoneBus.$emit('open', milestone);
    },
  },
});
