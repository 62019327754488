var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.certificate
    ? _c(
        "v-card",
        {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: _vm.visibilityChanged,
              expression: "visibilityChanged"
            }
          ],
          staticClass: "timeline-item-certificate-card clickable"
        },
        [
          _c(
            "v-layout",
            { staticStyle: { height: "100%" }, attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-progress-circular", {
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)"
                    },
                    attrs: { color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("profile-certificate", { attrs: { certificate: _vm.certificate } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }