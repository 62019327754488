var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline-item",
    {
      attrs: {
        color: "primary elevation-6",
        small: "",
        right: "",
        "fill-dot": ""
      }
    },
    [
      _c(
        "v-layout",
        { staticClass: "mx-0 mt-1" },
        [
          _c("v-flex", [
            _c("p", { staticClass: "starling-body font-weight-semi-bold" }, [
              _vm._v(_vm._s(_vm.displayDate))
            ])
          ])
        ],
        1
      ),
      _vm._l(this.timelineDate.items, function(item) {
        return _c("profile-timeline-item", {
          key: item.timestamp,
          attrs: { "timeline-item": item }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }