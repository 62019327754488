




















import Vue, { PropType } from 'vue';
import ProfileTimelineItem from '@/views/profile/partials/timeline/profile-timeline-item.vue';
import { TimelineDate } from '@/scripts/store/modules/my_starling/types';
import moment from 'moment';
import store from '@/scripts/store';

export default Vue.extend({
  name: 'profile-timeline-date',
  components: { ProfileTimelineItem },
  props: {
    timelineDate: Object as PropType<TimelineDate>,
  },
  computed: {
    currentLocale(): any {
      return store.getters.getCurrentLocale;
    },
    displayDate(): string {
      return moment(this.timelineDate.date).format(this.currentLocale?.code.startsWith('en') ? 'MMMM D' : 'D MMMM');
    },
  },
});
