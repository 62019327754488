var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        [_c("v-flex", { attrs: { xs12: "" } }, [_c("profile-milestones")], 1)],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "mt-3", attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [_c("profile-certificates")], 1)
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "mt-3", attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        [_c("v-flex", { attrs: { xs12: "" } }, [_c("profile-timelines")], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }