























import Vue from 'vue';
import ProfileCertificates from '@/views/profile/partials/certificates/profile-certificates.vue';
import ProfileTimelines from '@/views/profile/partials/timeline/profile-timelines.vue';
import ProfileMilestones from '@/views/profile/partials/milestones/profile-milestones.vue';

export default Vue.extend({
  name: 'profile-my-starling',
  components: { ProfileMilestones, ProfileTimelines, ProfileCertificates },
});
