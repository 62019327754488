import { render, staticRenderFns } from "./profile-timeline-item-checkup.vue?vue&type=template&id=ddc230e0&scoped=true&"
import script from "./profile-timeline-item-checkup.vue?vue&type=script&lang=ts&"
export * from "./profile-timeline-item-checkup.vue?vue&type=script&lang=ts&"
import style0 from "./profile-timeline-item-checkup.vue?vue&type=style&index=0&id=ddc230e0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddc230e0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VFlex,VHover,VLayout,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ddc230e0')) {
      api.createRecord('ddc230e0', component.options)
    } else {
      api.reload('ddc230e0', component.options)
    }
    module.hot.accept("./profile-timeline-item-checkup.vue?vue&type=template&id=ddc230e0&scoped=true&", function () {
      api.rerender('ddc230e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/partials/timeline/profile-timeline-item-checkup.vue"
export default component.exports