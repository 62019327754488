













































import Vue from 'vue';
import { mapState } from 'vuex';
import ProfileCertificate from '@/views/profile/partials/certificates/profile-certificate.vue';
import { Certificate } from '@/scripts/store/modules/my_starling/types';

export default Vue.extend({
  name: 'profile-certificates',
  components: { ProfileCertificate },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      certificates: (state: any): Certificate[] => {
        return state.myStarling.certificates && state.myStarling.certificates.certificates.length ? state.myStarling.certificates.certificates : [];
      },
      mostRecentCertificates(): Certificate[] {
        return this.certificates.slice(0, 3);
      },
    }),
  },
  mounted() {
    this.$store.dispatch('myStarling/getCertificates').finally(() => {
      this.loading = false;
    });
  },
});
