var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                directives: [
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: _vm.visibilityChanged,
                    expression: "visibilityChanged"
                  }
                ],
                staticClass: "timeline-item-checkup-card clickable",
                class: {
                  "elevation-4": !hover,
                  "elevation-12": hover
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onClick($event)
                  }
                }
              },
              [
                !_vm.assessmentData
                  ? _c(
                      "v-layout",
                      {
                        staticStyle: { height: "100%" },
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-progress-circular", {
                              staticStyle: {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)"
                              },
                              attrs: { color: "primary", indeterminate: "" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "v-card-text",
                      { staticClass: "pa-2" },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c("v-flex", { attrs: { grow: "" } }, [
                              _c(
                                "h5",
                                {
                                  staticClass: "starling-h5",
                                  staticStyle: {
                                    color: "var(--StarlingPrimary1)",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translate(0, -50%)"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "app.profile.my_starling.timeline.items.checkup.title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { xs4: "" } },
                              [
                                _c("checkup-results-radarchart", {
                                  style: "min-width:90px;max-width:100px;",
                                  attrs: {
                                    values: _vm.assessmentData,
                                    width: "100%",
                                    "show-drilldown": false,
                                    "show-animation": false,
                                    "show-labels": false
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                _vm.assessmentData
                  ? _c("profile-timeline-item-checkup-results", {
                      ref: "checkUpDialog",
                      attrs: {
                        "assessment-data": _vm.assessmentData,
                        date: _vm.timelineItem.content.date
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }