






































import Vue, { PropType } from 'vue';
import { TimelineItem } from '@/scripts/store/modules/my_starling/types';
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart.vue';
import { IAssessmentResult } from '@/scripts/store/modules/assessments/types';
import ProfileTimelineItemCheckupResults
  from '@/views/profile/partials/timeline/profile-timeline-item-checkup-results.vue';

export default Vue.extend({
  name: 'profile-timeline-item-checkup',
  components: { ProfileTimelineItemCheckupResults, CheckupResultsRadarchart },
  props: {
    timelineItem: { type: Object as PropType<TimelineItem>, required: true },
  },
  data() {
    return {
      loading: false,
      assessmentData: undefined as undefined | { [name: string]: IAssessmentResult },
    };
  },
  methods: {
    onClick() {
      if (this.assessmentData) {
        (this.$refs.checkUpDialog as any).onActivate();
      }
    },
    visibilityChanged(visible: boolean) {
      if (visible && !this.loading && !this.assessmentData) {
        this.loading = true;
        this.$store.dispatch('assessment/getMostRecentAssessmentsAtDate', this.timelineItem.content.date).then((res: {
          [name: string]: IAssessmentResult
        }) => {
          this.assessmentData = res;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
});
