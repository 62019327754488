













































import Vue from 'vue';
import { CORE_ASSESSMENT_NAMES, IAssessmentResult, NOT_EVALUATED } from '@/scripts/store/modules/assessments/types';
import AssessmentHistoryWrapper from '@/views/home/components/assessment-history-wrapper.vue';
import moment from 'moment';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'profile-timeline-item-checkup-results',
  components: { PrimaryButton, AssessmentHistoryWrapper },
  props: {
    assessmentData: {
      type: Object as () => { [name: string]: IAssessmentResult },
      required: true,
    },
    date: String,
  },
  data() {
    return {
      display: false as boolean,
    };
  },
  computed: {
    momentDate(): moment.Moment {
      return moment(this.date);
    },
    displayDate(): string {
      return this.momentDate.format('LL');
    },
    nonCoreAssessments(): { [name: string]: IAssessmentResult } {
      return Object.keys(this.assessmentData)
        .filter(key => !(CORE_ASSESSMENT_NAMES.includes(key)))
        .filter(key => !(NOT_EVALUATED === this.assessmentData[key].simplifiedValue))
        .reduce((obj, key) => {
          obj[key] = this.assessmentData[key];
          return obj;
        }, {} as { [name: string]: IAssessmentResult });
    },
  },
  methods: {
    onActivate(): void {
      this.display = true;
    },
    onClose(): void {
      this.display = false;
    },
  },
});
