var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                directives: [
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: _vm.visibilityChanged,
                    expression: "visibilityChanged"
                  }
                ],
                staticClass: "timeline-item-therapy-card clickable",
                class: {
                  "elevation-4": !hover || !_vm.hasUen,
                  "elevation-12": hover && _vm.hasUen
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onClick($event)
                  }
                }
              },
              [
                _vm.timelineItem && _vm.timelineItem.content
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", "gap-xs-2": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { "order-xs1": "", xs4: "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", "fill-height": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: { position: "relative" },
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _vm.image
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "element-part-thumbnail max-size"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "thumbnail",
                                                  attrs: {
                                                    src: _vm.image.imageSrc,
                                                    alt: _vm.image.altText
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm.loading
                                          ? _c("v-progress-circular", {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform:
                                                  "translate(-50%,-50%)"
                                              },
                                              attrs: {
                                                color: "primary",
                                                indeterminate: ""
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "py-2 pl-2", attrs: { xs8: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-block fill-width text-left"
                                        },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticClass: "starling-h5",
                                              staticStyle: {
                                                color: "var(--StarlingPrimary1)"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "markup-text" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.timelineItem.content
                                                          .title
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-block fill-width text-left"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "markup-text" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "starling-text"
                                                },
                                                [
                                                  _vm.timelineItem.content
                                                    .serie &&
                                                  _vm.timelineItem.content.serie
                                                    .title
                                                    ? _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.timelineItem
                                                              .content.serie
                                                              .title
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm.timelineItem.content
                                                    .serie &&
                                                  _vm.timelineItem.content.serie
                                                    .title &&
                                                  _vm.timelineItem.content
                                                    .duration
                                                    ? _c("span", [
                                                        _vm._v(" ~ ")
                                                      ])
                                                    : _vm._e(),
                                                  _vm.duration
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "durationFormat"
                                                            )(_vm.duration)
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }