var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "content-class": "radius-15",
        "max-width": "1000px"
      },
      model: {
        value: _vm.display,
        callback: function($$v) {
          _vm.display = $$v
        },
        expression: "display"
      }
    },
    [
      _vm.display
        ? _c(
            "v-card",
            {
              class: {
                "pa-2": _vm.$vuetify.breakpoint.smAndDown,
                "pa-3": _vm.$vuetify.breakpoint.mdOnly,
                "pa-5": _vm.$vuetify.breakpoint.lgAndUp
              },
              attrs: { flat: "" }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h2", { staticClass: "starling-h2 pb-2" }, [
                      _vm._v(_vm._s(_vm.displayDate))
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("v-icon", { on: { click: _vm.onClose } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  class: {
                    "mb-4": Object.keys(_vm.nonCoreAssessments).length > 0
                  },
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("assessment-history-wrapper", {
                        attrs: {
                          part: {},
                          "show-selected-title": true,
                          "selected-day": _vm.date
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.nonCoreAssessments, function(data, key) {
                return _c(
                  "v-layout",
                  {
                    key: key,
                    staticClass: "mb-2",
                    attrs: { row: "", wrap: "" }
                  },
                  [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("p", { staticClass: "starling-body" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.assessments." + key.toUpperCase())
                                .label
                            ) + ":"
                          )
                        ]),
                        _c("span", { staticClass: "dynamic-text" }, [
                          _vm._v(" " + _vm._s(data.simplifiedDescription))
                        ])
                      ])
                    ])
                  ],
                  1
                )
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "align-center": "",
                        "justify-space-around": "",
                        "gap-xs-3": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "primary-button",
                            {
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onClose($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.actions.done")) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }