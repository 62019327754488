var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", [
                _c(
                  "h3",
                  {
                    staticClass: "starling-h3",
                    staticStyle: { position: "relative" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("app.profile.my_starling.milestones.title")
                        ) +
                        "\n          "
                    ),
                    _vm.milestones.length > 3
                      ? _c("router-link", {
                          attrs: { to: { name: "milestones" }, custom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var navigate = ref.navigate
                                  var isActive = ref.isActive
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "StarlingDarkGrey--text ma-0 pa-0",
                                        staticStyle: {
                                          display: "inline-block",
                                          position: "absolute",
                                          right: "0",
                                          top: "50%",
                                          transform: "translate(0, -50%)"
                                        },
                                        attrs: { flat: "", small: "" },
                                        on: { click: navigate }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "label",
                                            class: {
                                              "StarlingPrimary1--text": isActive
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "app.navigation.view_all"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            976890856
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          !_vm.loading
            ? _c(
                "v-layout",
                { staticClass: "pt-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _vm.mostRecentMilestones &&
                      _vm.mostRecentMilestones.length
                        ? [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              _vm._l(_vm.mostRecentMilestones, function(
                                milestone
                              ) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: milestone.id,
                                    staticClass: "pa-1",
                                    attrs: { xs4: "" }
                                  },
                                  [
                                    _c("profile-milestone", {
                                      staticClass: "pa-1",
                                      attrs: { milestone: milestone },
                                      on: { selected: _vm.onSelect }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                        : [
                            _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "app.profile.my_starling.milestones.subtitle"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "primary",
                          width: "3",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }