import { TimelineDate, TimelineItem, Timelines, TimelineYear } from '@/scripts/store/modules/my_starling/types';

const timelineItemFilter = (item: TimelineItem, selectedFilters: Array<string>): TimelineItem | undefined => {
  if (!selectedFilters || !selectedFilters.length) return item;

  switch (item.content.type) {
    case 'THERAPY':
      return selectedFilters.indexOf(item.content.sessionType) >= 0 ? item : undefined;
    default:
      return selectedFilters.indexOf(item.content.type) >= 0 ? item : undefined;
  }
  return undefined;
};

const timelineDateFilter = (date: TimelineDate, selectedFilters: Array<string>): TimelineDate | undefined => {
  if (!selectedFilters || !selectedFilters.length) return date;
  const filteredItems: TimelineItem[] = date.items.map(i => timelineItemFilter(i, selectedFilters)).filter(i => i !== undefined).map(i => i as TimelineItem);
  if (!filteredItems.length) return undefined;
  return { date: date.date, items: filteredItems };
};

const timelineYearFilter = (year: TimelineYear, selectedFilters: Array<string>): TimelineYear | undefined => {
  if (!selectedFilters || !selectedFilters.length) return year;
  const filteredDates: TimelineDate[] = year.dates.map(d => timelineDateFilter(d, selectedFilters)).filter(d => d !== undefined).map(d => d as TimelineDate);
  if (!filteredDates.length) return undefined;
  return { year: year.year, dates: filteredDates };
};

export const timelinesFilter = (timelines: Timelines, selectedFilters: Array<string>): Timelines => {
  if (!selectedFilters || !selectedFilters.length) return timelines;
  const filteredTimelines: TimelineYear[] = timelines.timelines.map(d => timelineYearFilter(d, selectedFilters)).filter(d => d !== undefined).map(d => d as TimelineYear);
  return { timelines: filteredTimelines };
};

function gatherAvailableFiltersForItems(item: TimelineItem): Set<string> {
  const set: Set<string> = new Set<string>();

  switch (item.content.type) {
    case 'THERAPY':
      set.add(item.content.sessionType);
      break;
    default:
      set.add(item.content.type);
      break;
  }

  return set;
}

const gatherAvailableFiltersForDates = (date: TimelineDate): Set<string> => {
  let set: Set<string> = new Set<string>();

  date.items.forEach(timelineItem => {
    set = new Set([ ...set, ...gatherAvailableFiltersForItems(timelineItem) ]);
  });
  return set;
};

const gatherAvailableFiltersForYears = (year: TimelineYear): Set<string> => {
  let set: Set<string> = new Set<string>();

  year.dates.forEach(timelineDate => {
    set = new Set([ ...set, ...gatherAvailableFiltersForDates(timelineDate) ]);
  });
  return set;
};

export const gatherAvailableFilters = (timelines: Timelines): Array<string> => {
  let set: Set<string> = new Set<string>();

  timelines.timelines.forEach(timelineYear => {
    set = new Set([ ...set, ...gatherAvailableFiltersForYears(timelineYear) ]);
  });

  return Array.from(set.values());
};
