


















































import Vue, { PropType } from 'vue';
import { TimelineItem } from '@/scripts/store/modules/my_starling/types';
import { ITherapyImage } from '@/scripts/store/modules/therapy/types';
import moment from 'moment';

export default Vue.extend({
  name: 'profile-timeline-item-therapy',
  props: {
    timelineItem: Object as PropType<TimelineItem>,
  },
  data() {
    return {
      loading: false,
      image: undefined as undefined | { altText: string, imageSrc: string },
    };
  },
  computed: {
    hasUen(): boolean {
      return this.timelineItem?.content?.startUen && this.timelineItem?.content?.startUen.length;
    },
    duration(): moment.Duration | null {
      return this.timelineItem?.content?.duration ? moment.duration(this.timelineItem.content.duration) : null;
    },
  },
  beforeDestroy() {
    if (this.image && this.image.imageSrc) {
      URL.revokeObjectURL(this.image.imageSrc);
    }
  },
  methods: {
    onClick(): void {
      this.$log.info('Open therapy');
      if (this.hasUen) {
        switch (this.timelineItem?.content?.sessionType) {
          case 'EXERCISE':
            this.$router.push({ name: 'exercises-run', params: { uen: this.timelineItem?.content.startUen } });
            break;
          default:
            this.$router.push({ name: 'products-run', params: { uen: this.timelineItem?.content.startUen } });
        }
      }
    },
    visibilityChanged(visible: boolean) {
      if (visible && !this.loading) {
        if (this.timelineItem?.content?.thumbnail && this.timelineItem?.content?.thumbnail.length) {
          this.loading = true;
          this.$store.dispatch('therapy/getTherapyImage', this.timelineItem?.content.thumbnail)
            .then((res: ITherapyImage) => {
              this.image = {
                imageSrc: URL.createObjectURL(new Blob([ res.image ], { type: 'image/svg+xml' })),
                altText: res?.altText,
              };
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
});
