var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("h4", { staticClass: "starling-h4" }, [
          _vm._v(_vm._s(_vm.timeline.year))
        ])
      ]),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-timeline",
            { attrs: { dense: "", "align-top": "" } },
            _vm._l(_vm.timeline.dates, function(date, index) {
              return _c("profile-timeline-date", {
                key: index,
                attrs: { "timeline-date": date }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }