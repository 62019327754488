var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", [
                _c("h3", { staticClass: "starling-h3 pb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("app.profile.my_starling.timeline.title"))
                  )
                ])
              ])
            ],
            1
          ),
          _vm.filteredTimelines
            ? [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "cursor-pointer filter-chip mr-1 mt-1",
                            class: {
                              active: _vm.selectedFilters.length === 0,
                              "starling-body": _vm.$vuetify.breakpoint.xsOnly,
                              "starling-text": _vm.$vuetify.breakpoint.smAndUp
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.selectAll($event)
                              }
                            }
                          },
                          [_vm._v("\n            All\n          ")]
                        ),
                        _vm._l(_vm.availableFilters, function(filter) {
                          return _c(
                            "v-chip",
                            {
                              key: filter,
                              staticClass:
                                "cursor-pointer filter-chip mr-1 mt-1",
                              class: {
                                active: _vm.isSelected(filter),
                                "starling-body": _vm.$vuetify.breakpoint.xsOnly,
                                "starling-text": _vm.$vuetify.breakpoint.smAndUp
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.toggleFilter(filter)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "app.profile.my_starling.timeline.filters." +
                                        filter +
                                        ".label"
                                    )
                                  ) +
                                  "\n            "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._l(_vm.filteredTimelines.timelines, function(timeline) {
                  return [
                    _c("profile-timeline", {
                      key: timeline.year,
                      staticClass: "mt-3",
                      attrs: {
                        timeline: timeline,
                        "selected-filters": _vm.selectedFilters
                      }
                    })
                  ]
                })
              ]
            : !_vm.loading
            ? [
                _c("p", { staticClass: "starling-body" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.profile.my_starling.timeline.subtitle")
                      ) +
                      "\n      "
                  )
                ])
              ]
            : _c(
                "v-layout",
                { staticClass: "my-2", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "primary",
                          width: "3",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }