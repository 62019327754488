











import Vue, { PropType } from 'vue';
import { Certificate, TimelineItem } from '@/scripts/store/modules/my_starling/types';
import ProfileCertificate from '@/views/profile/partials/certificates/profile-certificate.vue';

export default Vue.extend({
  name: 'profile-timeline-item-certificate',
  components: { ProfileCertificate },
  props: {
    timelineItem: Object as PropType<TimelineItem>,
  },
  data() {
    return {
      loading: false,
      certificate: undefined as undefined | Certificate,
    };
  },
  methods: {
    visibilityChanged(visible: boolean) {
      if (visible && !this.loading && !this.certificate) {
        this.loading = true;
        this.$store.dispatch('myStarling/getCertificate', this.timelineItem?.content?.id as string).then((res: Certificate) => {
          this.certificate = res;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
});
