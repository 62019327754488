















import Vue, { PropType } from 'vue';
import ProfileTimelineDate from '@/views/profile/partials/timeline/profile-timeline-date.vue';
import { TimelineYear } from '@/scripts/store/modules/my_starling/types';

export default Vue.extend({
  name: 'profile-timeline',
  components: { ProfileTimelineDate },
  props: {
    timeline: { type: Object as PropType<TimelineYear>, required: true },
  },
});
